import { Field, FieldProps } from 'formik';
import React, { ReactNode } from 'react';
import cn from 'classnames';
import moment from 'moment';
import { DatePicker } from 'antd';
import _ from 'lodash';

import { Control } from '../../components/Form/Control';

interface Props {
    name: string;
    label?: ReactNode;
    placeholder?: string;
    disabled?: boolean;
    format?: string;
}

export default ({ name, label, format = 'YYYY-MM-DD', ...bag }: Props) => (
    <Field name={name}>
        {({ form: { errors, setFieldValue, values } }: FieldProps<any>) => {
            return (
                <Control label={label} error={_.get(errors, name)}>
                    <DatePicker
                        allowClear={false}
                        className={cn({ 'ant-input-warning': _.get(errors, name) })}
                        format={'DD.MM.YYYY'}
                        defaultPickerValue={moment('2000-01-01', 'YYYY-MM-DD')}
                        onChange={(value) => setFieldValue(name, value ? value.format(format) : null)}
                        value={_.get(values, name) ? moment(_.get(values, name), format) : undefined}
                        {...bag}
                    />
                </Control>
            );
        }}
    </Field>
);

import {useQuery, useQueryClient} from 'react-query';
import { apiClient } from '../../../../../../../../common/services/api-client';
import { VariantsSelection } from '../VariantsDropdown';
import { oc } from 'ts-optchain';
import { useEffect, useState } from 'react';

export const isCalculationDisabled = (variantsSelection?: VariantsSelection[]) => {
    return (variantsSelection || []).reduce((sum, item) => sum + item.count, 0) > 10;
};

const getSelection = (variantsSelection: VariantsSelection[]) => {
    if (isCalculationDisabled(variantsSelection)) {
        const [item] = variantsSelection;

        return [{ ...item, count: 1 }];
    }

    return variantsSelection;
};

export const useDatePrices = (dates: string[], variantsSelection: VariantsSelection[], enabled: boolean) => {
    const [prices, setPrices] = useState<Record<string, number>>({});
    const queryClient = useQueryClient();

    const selection = getSelection(variantsSelection);

    const queryKey = ['date-prices', dates, selection];

    const queryState = queryClient.getQueryState(queryKey);
    const isCached = queryState && queryState.status === 'success';

    const result = useQuery({
        queryKey,
        queryFn: () => apiClient.post(
            `/v2/transaction/prices`,
            {
                origin: 'eshop',
                entries: selection.map((item) => ({
                    variantId: item.id,
                    count: item.count,
                })),
                dates,
            },
        ),
        enabled: enabled && !isCached,
    });

    useEffect(() => {
        setPrices({
            ...prices,
            ...oc(result).data.data({}),
        });
    }, [result.data]);

    return {
        prices,
        isLoading: result.isLoading,
        isCached
    };
};

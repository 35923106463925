import React, { Fragment, useEffect, useState } from 'react';
import { Col, DatePicker, Row } from 'antd';
import moment from 'moment';
import { oc } from 'ts-optchain';

import { Button, Price, TotalPrice } from '../../index';
import { ModalSizes } from '../../../constants/modal';
import EnhancedModal from '../../Modal';
import { Product } from '../../../models/Product';
import LocalizedString from '../../LocalizedString';
import { i18n } from '../../../services/i18n';
import { environment } from '../../../../environments/environment';
import { VariantsSelection } from '../../../../features/shopping/features/eshop/components/ProductSelect/components/VariantsDropdown';
import { findNextEnabledDate, isProductAndSelectionValidAtDate } from '../../../utils/visibility-helpers';
import TimeCarousel
    from '../../../../features/shopping/features/eshop/components/ProductSelect/components/TimeCarousel';
import { TimeSlotType } from '../../../../features/shopping/ducks';
import CalendarDatePicker from '../CalendarDatePicker';
import PriceCalendarCell from '../CalendarDatePicker/components/PriceCalendarCell';
import CalendarCell from '../CalendarDatePicker/components/CalendarCell';
import {
    isCalculationDisabled
} from '../../../../features/shopping/features/eshop/components/ProductSelect/components/DateSelectCarousel/useDatePrices';

export interface DatePickerChangeValues {
    arrival: string;
    inclusiveValidity: string[];
    time: string;
    timeSlotType?: TimeSlotType;
    timeSlotGroup?: string;
}

interface Props {
    visible?: boolean;
    onConfirm: (date: DatePickerChangeValues) => void;
    onCancel: (value: boolean) => void;
    closeModal?: () => void;
    product: Product | undefined;
    variantSelection: VariantsSelection[];
    price: any;
    onChange?: (value: DatePickerChangeValues) => void;
}

function DatePickerModal({ visible, onConfirm, onCancel, product, variantSelection, price, onChange }: Props) {
    const [arrival, setDate] = useState<string>('');
    const [time, setTime] = useState<string>('');
    const [timeSlotType, setTimeSlotType] = useState<TimeSlotType | undefined>(undefined);

    const isTimeslot = !!oc(product).hasTimeSlots();
    const isTimeSlotBase = isTimeslot || oc(product).meta.dateView('DatePicker') === 'DateStrip';
    const metaDateView = oc(product).meta.dateView('DatePicker');


    const calculationDisabled = isCalculationDisabled(variantSelection);

    useEffect(
        () => {
            if (arrival) {
                onChange && onChange(getChangeValue());
            }
        },
        [arrival, time]
    );

    useEffect(
        () => {
            setTime('');
            setTimeSlotType(undefined);
        },
        [arrival]
    );

    useEffect(
        () => {
            const d = findNextEnabledDate(getAllowedRanges, moment(oc(product).validity.to()));
            const date = d ? d.startOf('day').format() : '';

            if (date !== arrival) {
                setDate(date);
                setTime('');
                setTimeSlotType(undefined);
            }
        },
        [visible]
    );

    function getChangeValue(): DatePickerChangeValues {
        const dayCount = oc(product).meta.tokenValidityUnit() === 'days'
            ? Number(oc(product).meta.tokenValidityAmount('1'))
            : 1;
        const inclusiveValidity = new Array(dayCount)
            .fill(1)
            .map((_, i) => moment(arrival).add(i, 'days').format('YYYY-MM-DD'));

        return {
            arrival: moment(arrival).format(),
            inclusiveValidity,
            time,
            timeSlotType,
            timeSlotGroup: oc(product).timeSlotGroup(),
        };
    }

    function handleDateSelect(value) {
        setDate(value);
    }

    function addToCart() {
        onCancel(false);
        onConfirm(getChangeValue());
    }

    function getAllowedRanges(current) {
        return environment.getGlobalDisabledDates(current) ||
            !isProductAndSelectionValidAtDate(product, variantSelection.map(vs => vs.id), current);
    }

    function getSelectedVariants() {
        return variantSelection.reduce((acc, vs) => {
            const name = oc(oc(product).variants([]).find(v => v._id === vs.id)).name[i18n.language]('');
            return `${acc}, ${vs.count}x ${name}`;
        }, '');
    }

    function isButtonDisabled(): boolean {
        return !arrival ||
            !isProductAndSelectionValidAtDate(product, variantSelection.map(vs => vs.id), arrival) ||
            (isTimeslot && !time);
    }

    const renderPriceCell = (date, currentMonth) => (
        <PriceCalendarCell
            currentMonth={currentMonth}
            disabledDate={getAllowedRanges}
            selectedDate={moment(arrival)}
            date={date}
            selectedVariants={variantSelection || []}
        />
    );

    const renderCell = (date, currentMonth) => (
        <CalendarCell
            currentMonth={currentMonth}
            disabledDate={getAllowedRanges}
            selectedDate={moment(arrival)}
            date={date}
        />
    );

    return (
        <EnhancedModal
            style={{ top: '25px' }}
            visible={visible}
            footer={null}
            width={isTimeSlotBase ? ModalSizes.timeslots : ModalSizes.default}
            closable={false}
            onCancel={onCancel.bind(this, false)}
        >
            <div className="modal-container">
                <Row>
                    <h1> {i18n.t('calendarModal.title')} </h1>
                </Row>
                {!isTimeSlotBase &&
                    <div className="modal-container__picker-container">
                        <CalendarDatePicker
                            onDateSelect={handleDateSelect}
                            disabledDate={getAllowedRanges}
                            selectedDate={moment(arrival)}
                            renderCell={metaDateView === 'Calendar' ? renderPriceCell : renderCell}
                            footer={calculationDisabled ? i18n.t('ticketSelect.calculationLimit') : undefined}
                        />
                    </div>
                }
                {isTimeSlotBase &&
                    <Row gutter={16} type="flex" justify="center" style={{ width: '100%' }}>
                        <Col xs={24} md={24} className="item-1">
                            <TimeCarousel
                                product={product}
                                selectedVariants={variantSelection}
                                arrival={arrival || ''}
                                setDate={setDate}
                                time={time}
                                variantCount={variantSelection.reduce((acc, v) => acc + v.count, 0)}
                                setTime={setTime}
                                setTimeSlotType={setTimeSlotType}
                                timeSlotGroup={oc(product).timeSlotGroup('')}
                                onlyDateRow={!isTimeslot}
                            />
                        </Col>
                    </Row>
                }
                {product && (
                    <Fragment>
                        <Row className={'modal-container__product-info'}>
                            <LocalizedString value={product.name} />
                            {getSelectedVariants()}{' '}
                            {i18n.t('calendarModal.valid')}{' '}
                            {moment(arrival).format('DD.MM.YYYY')}{' '}
                            {time}
                        </Row>
                        <Row className={'modal-container__description'}>
                            <LocalizedString value={product.description} />
                        </Row>
                    </Fragment>
                )}
                <Row className={'modal-container__product-info'}>
                    <TotalPrice
                        type="positive"
                        pricePosition="top"
                        withDiscount={
                            <Price value={price && price.discounted ? price.discounted : 0} />
                        }
                        withoutDiscount={
                            <Fragment>
                                {price && price.credit ?
                                    i18n.t('ticketSelect.credit.priceWithoutDiscount') :
                                    i18n.t('ticketSelect.priceWithoutDiscount')
                                }{' '}
                                <Price value={price && price.price ? price.price : 0} />
                            </Fragment>
                        }
                        displayDiscount={price && price.price !== price.discounted}
                    />
                </Row>
                <Row>
                    <Button
                        onClick={addToCart}
                        disabled={isButtonDisabled()}
                        type="success"
                        block
                    >
                        {i18n.t('ticketSelect.addToCart')}
                    </Button>
                </Row>
            </div>
        </EnhancedModal>
    );
}

export default DatePickerModal;

import { message } from 'antd';
import localForage from 'localforage';
import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import { all, call, fork, put, select, takeEvery, takeLatest } from 'redux-saga/effects';
import { oc } from 'ts-optchain';
import moment from 'moment';

import { transactionsApi } from '../../../common/api/transaction';
import { AppState, Season } from '../../../common/models/AppState';
import { ProductSelection } from '../../../common/models/Cart';
import { Product } from '../../../common/models/Product';
import {
    createActionCreator,
    createActionType,
    createApiActionCreators,
    createReducer,
    RequestActionTypes,
} from '../../../common/utils/redux-helpers';
import { environment } from '../../../environments/environment';
import { api } from '../api';
import { checkout, checkoutSaga, CheckoutState } from '../features/checkout/ducks';
import { confirmation, confirmationSaga, ConfirmationState } from '../features/confirmation/ducks';

import {
    addTransactionVoucherAction,
    cart,
    CartActionTypes,
    CartState, removeItemFromCartAction,
    resetCartAction,
    selectCartItems, selectCartTransactionEntries,
    validateTransactionActions,
} from './cart';
import {
    clearEmailStateAction,
    emailValidateActions,
    order,
    OrderActionTypes,
    OrderState,
    selectAsyncRegisteredId
} from './order';
import { BannerEntry } from '../../../common/models/Banner';
import { Config } from '../../../common/models/Config';
import { i18n } from '../../../common/services/i18n';
import {
    resetTemporaryCardsAction,
    selectUserProfile,
} from '../../user/ducks';
import _ from 'lodash';
import { selectOccupiedTimeSlots } from '../../dateChange/ducks';
import { b2bConfirmation, b2bConfirmationSaga, B2bConfirmationState } from '../features/b2b-confirmation/ducks';
import { isProductVisibleForUser } from '../../../common/utils/isProductVisibleForUser';
import {
    EntryBase,
    EntryType,
    getTransactionBenefits,
    Transaction,
    TransactionBase,
} from '../../../common/models/NewTransaction';
import { UserProfile } from '../../../common/models/User';

// Action types
export enum EshopActionTypes {
    FetchProducts = '@@Eshop/FETCH_PRODUCTS',
    FetchCategories = '@@Eshop/FETCH_CATEGORIES',
    ChangeSeason = '@@Eshop/CHANGE_SEASON',
    FetchSeasons = '@@Eshop/FETCH_SEASONS',
    SetInitalizing = '@@Eshop/SET_INITIALIZING',
    ValidateProductVoucher = '@@Eshop/VALIDATE_PRUDUCT_VOUCHER',
    ResetValidateProductVoucher = '@@Eshop/RESET_PRUDUCT_VOUCHER',
    ValidateProductSelection = '@@Eshop/VALIDATE_SELECTION',
    ValidateSingleVariant = '@@Eshop/VALIDATE_SINGLE_VARIANTS',
    ValidateProductSelectionDiscount = '@@Eshop/VALIDATE_SELECTION_DISCOUNT',
    ResetProductSelectionValidation = '@@Eshop/RESET_PRODUCT_VALIDATION_SELECTION',
    FetchBanners = '@@Eshop/FETCH_BANNERS',
    FetchConfig = '@@Eshop/FETCH_CONFIG',
    GetTimeSlotOptions = '@@Eshop/GET_TIME_SLOT_OPTIONS',
    ResetTimeSlotOptions = '@@Eshop/RESET_TIME_SLOT_OPTIONS',
    GetTimeSlotDailyOptions = '@@Eshop/GET_TIME_SLOT_DAILY_OPTIONS',
}

// Actions
export const fetchProductsActions = createApiActionCreators(EshopActionTypes.FetchProducts);
export const fetchBannersActions = createApiActionCreators(EshopActionTypes.FetchBanners);
export const fetchConfigActions = createApiActionCreators(EshopActionTypes.FetchConfig);
export const fetchCategoriesActions = createApiActionCreators(EshopActionTypes.FetchCategories);
export const changeSeasonAction = createActionCreator(EshopActionTypes.ChangeSeason);
export const setInitializingAction = createActionCreator(EshopActionTypes.SetInitalizing);
export const fetchSeasonsAction = createApiActionCreators(EshopActionTypes.FetchSeasons);
export const resetEshopTransactionAction = createActionCreator(
    EshopActionTypes.ResetProductSelectionValidation
);

export const validateProductSelectionActions = createApiActionCreators(
    EshopActionTypes.ValidateProductSelection
);

export const validateSingleVariantActions = createApiActionCreators(
    EshopActionTypes.ValidateSingleVariant
);

export const validateProductSelectionActionsDiscount = createApiActionCreators(
    EshopActionTypes.ValidateProductSelectionDiscount
);

export const validateProductVoucherAction = createApiActionCreators(
    EshopActionTypes.ValidateProductVoucher
);

export const resetValidateProductVoucherAction = createActionCreator(
    EshopActionTypes.ResetValidateProductVoucher
);

export const getTimeSlotOptionsActions = createApiActionCreators(
    EshopActionTypes.GetTimeSlotOptions
);

export const resetTimeSlotOptionsAction = createActionCreator(EshopActionTypes.ResetTimeSlotOptions);

export const getTimeSlotDailyOptionsActions = createApiActionCreators(
    EshopActionTypes.GetTimeSlotDailyOptions
);

// Reducers

export interface ProductVoucherValidation {
    needMore: boolean;
    error: string | null;
    valid: boolean;
    voucher: null | string;
}

export interface SingleVariant {
    variant: string;
    arrival: string;
}

interface EshopState {
    transaction: TransactionBase | null;
    singleVariants: Record<string, EntryBase>;
}

interface DiscountTransactionsObject {
    product: string;
    transaction: TransactionBase;
}

interface SingleVariantObject {
    variant: string;
    entry: EntryBase;
}

export enum RequestStatus {
    Loading,
    Loaded,
    Failed,
}

export interface TimeSlotTime {
    available: number;
    occupied: number;
}

export enum TimeSlotType {
    day = 'day',
    range = 'range',
}

export interface TimeSlotTranslation {
    title?: Record<string, string>;
    description?: Record<string, string>;
}

interface TimeSlotState {
    status: RequestStatus;
    type?: TimeSlotType;
    dates?: Record<string, Record<string, TimeSlotTime>>;
    translations?: {
        eshop?: TimeSlotTranslation,
        ssp?: TimeSlotTranslation
    };
    available?: number;
    occupied?: number;
    groupId?: string;
}

export interface ShoppingState {
    initializing: boolean;
    season: Season;
    seasons: Season[];
    categories: Product[];
    products: Product[];
    cart: CartState;
    order: OrderState;
    eshop: EshopState;
    checkout: CheckoutState;
    confirmation: ConfirmationState;
    b2bConfirmation: B2bConfirmationState;
    voucherValidation: ProductVoucherValidation;
    discountTransactions: Record<string, TransactionBase>;
    banners: BannerEntry[];
    config: Config;
    timeSlots: TimeSlotState;
    dayTimeSlots: Record<string, Record<string, TimeSlotTime>>;
}

const initialState: Partial<ShoppingState> = {
    initializing: true,
    season: {} as Season,
    seasons: [],
    categories: [],
    products: [],
    cart: {
        items: [],
        open: false,
        transaction: null,
        credit: 0,
        products: [],
        transactionVouchers: [],
    },
    voucherValidation: {
        needMore: false,
        error: null,
        valid: false,
        voucher: null,
    },
    eshop: {
        transaction: null,
        singleVariants: {}
    },
    discountTransactions: {},
    banners: [],
    config: {
        enabled: true,
        seasonPicker: false,
        dualPrices: true,
    },
    timeSlots: {
        status: RequestStatus.Loading,
    },
    dayTimeSlots: {},
};

const eshop = createReducer(initialState.eshop, {
    [EshopActionTypes.ValidateProductSelection]: {
        [RequestActionTypes.SUCCESS]: (state: EshopState, payload: Transaction) => ({
            ...state,
            transaction: payload,
        }),
    },
    [EshopActionTypes.ValidateSingleVariant]: {
        [RequestActionTypes.SUCCESS]: (state: EshopState, payload: SingleVariantObject) => ({
            ...state,
            singleVariants: {
                ...state.singleVariants,
                [payload.variant]: payload.entry
            },
        }),
    },
    [EshopActionTypes.ResetProductSelectionValidation]: () => initialState.eshop,
});

const discountTransactions = createReducer(initialState.discountTransactions, {
    [EshopActionTypes.ValidateProductSelectionDiscount]: {
        [RequestActionTypes.SUCCESS]: (state: EshopState, payload: DiscountTransactionsObject) => ({
            ...state,
            [payload.product]: payload.transaction,
        }),
    },
    [EshopActionTypes.ResetProductSelectionValidation]: () => initialState.eshop,
});

const season = createReducer(initialState.season, {
    [EshopActionTypes.ChangeSeason]: (state: AppState, payload: Season) => {
        window['selectedSeason'] = payload.type;
        return payload;
    },
});

const voucherValidation = createReducer(initialState.voucherValidation, {
    [EshopActionTypes.ValidateProductVoucher]: {
        [RequestActionTypes.SUCCESS]: (state, payload) => ({
            needMore: payload.needMore,
            valid: true,
            voucher: payload.voucher,
            error: null,
        }),
        [RequestActionTypes.FAILURE]: (state, payload) => ({
            needMore: false,
            valid: false,
            voucher: null,
            error: payload,
        }),
    },
    [EshopActionTypes.ResetValidateProductVoucher]: () => initialState,
});

const initializing = createReducer(initialState.season, {
    [EshopActionTypes.SetInitalizing]: (state: AppState, payload: boolean) => payload,
});

const products = createReducer(initialState.products, {
    [EshopActionTypes.FetchProducts]: {
        [RequestActionTypes.SUCCESS]: (state: Product[], payload: any) => payload,
    },
});

const categories = createReducer(initialState.categories, {
    [EshopActionTypes.FetchCategories]: {
        [RequestActionTypes.SUCCESS]: (state, payload) => payload,
    },
});

const seasons = createReducer(initialState.seasons, {
    [EshopActionTypes.FetchSeasons]: {
        [RequestActionTypes.SUCCESS]: (state: Season[], payload: Season[]) => {
            const activeSeason = payload.find(s => s.active);
            window['selectedSeason'] = activeSeason ? activeSeason.type : undefined;
            return payload;
        },
    },
});

const banners = createReducer(initialState.banners, {
    [EshopActionTypes.FetchBanners]: {
        [RequestActionTypes.SUCCESS]: (state: BannerEntry[], payload: any) => payload,
    },
});

const config = createReducer(initialState.config, {
    [EshopActionTypes.FetchConfig]: {
        [RequestActionTypes.SUCCESS]: (state: Config, payload: any) => payload,
    },
});

const timeSlots = createReducer(initialState.timeSlots, {
    [EshopActionTypes.GetTimeSlotOptions]: {
        [RequestActionTypes.REQUEST]: (state: TimeSlotState) => ({ ...state, status: RequestStatus.Loading }),
        [RequestActionTypes.SUCCESS]: (state: TimeSlotState, payload: any) => payload,
        [RequestActionTypes.FAILURE]: () => ({ status: RequestStatus.Failed }),
    },
    [EshopActionTypes.ResetTimeSlotOptions]: () => ({ status: RequestStatus.Loading })
});

const dayTimeSlots = createReducer(initialState.dayTimeSlots, {
    [EshopActionTypes.GetTimeSlotDailyOptions]: {
        [RequestActionTypes.SUCCESS]: (state: Record<string, Record<string, TimeSlotTime>>, payload: any) => payload,
    },
});

const shopping = combineReducers<ShoppingState>({
    initializing,
    categories,
    products,
    season,
    cart,
    order,
    confirmation,
    b2bConfirmation,
    voucherValidation,
    eshop,
    checkout,
    discountTransactions,
    banners,
    config,
    seasons,
    timeSlots,
    dayTimeSlots,
});

export default persistReducer(
    {
        key: 'shopping',
        version: 0,
        storage: localForage,
        whitelist: ['cart', 'categories', 'season', 'products', 'banners', 'config', 'order'],
        debug: environment.debug,
    },
    shopping
);

// Selectors
export function selectShopping(state: AppState) {
    return state.shopping;
}

export function selectEshopConfig(state: AppState) {
    return selectShopping(state).config;
}

export function selectProducts(state: AppState) {
    const profile = selectUserProfile(state);

    return selectShopping(state).products.filter(c => isProductVisibleForUser(c, profile));
}

export function selectSeason(state: AppState) {
    return selectShopping(state).season;
}

export function selectSeasons(state: AppState) {
    return selectShopping(state).seasons;
}

export function selectCategories(state: AppState) {
    const profile = selectUserProfile(state);

    return selectShopping(state).categories.filter(c => isProductVisibleForUser(c, profile));
}

export function selectProductVoucherValidation(state: AppState) {
    return selectShopping(state).voucherValidation;
}

export function selectEshop(state: AppState) {
    return selectShopping(state).eshop;
}

export function selectDiscountTransaction(state: AppState) {
    return selectShopping(state).discountTransactions;
}

export function selectEshopTransaction(state: AppState) {
    return selectEshop(state).transaction;
}

export function selectEshopTotal(state: AppState) {
    return oc(selectEshopTransaction(state)).amount.base() as number;
}

export function selectDiscountBadges(state: AppState, product: string | undefined) {
    return product
        ? getTransactionBenefits(selectDiscountTransaction(state)[product])
        : [];
}

export function selectDiscountEntries(state: AppState, product: string | undefined) {
    return product ? oc(selectDiscountTransaction(state)[product]).entries([]) : [];
}

export function selectTransactionPrice(state: AppState, key: string) {
    const transactions = selectDiscountTransaction(state);

    if (!transactions[key]) {
        return {};
    }

    return {
        price: oc(transactions[key]).amount.base(0),
        discounted: oc(transactions[key]).amount.club(0),
        withoutCredits: 0, // ToDo
        lastPrice: 0, // ToDo
    };
}

export function selectDiscountPrices(state: AppState) {
    const val = selectDiscountTransaction(state);

    return Object
        .keys(val)
        .filter(p => val[p] && p !== 'singleVariants' && p !== 'transaction')
        .reduce((acc, p) => ({
            ...acc,
            [p]: {
                price: oc(val[p]).amount.base(0),
                discounted: oc(val[p]).amount.club(0),
                withoutCredits: 0, // ToDo
                lastPrice: 0, // ToDo
            }
        }), {});
}

export function selectEshopEntries(state: AppState) {
    return oc(selectEshopTransaction(state)).entries([]);
}

export function selectEshopSingleEntries(state: AppState) {
    return oc(selectEshop(state)).singleVariants({});
}

export function selectEshopBanners(state: AppState) {
    return selectShopping(state).banners;
}

export function selectEshopBadges(state: AppState) {
    return getTransactionBenefits(selectEshopTransaction(state));
}

export function selectEshopTotalDiscounted(state: AppState) {
    return oc(selectEshopTransaction(state)).amount.club() as number;
}

export function selectTimeSlotsLoadStatus(state: AppState) {
    return oc(selectShopping(state)).timeSlots.status(RequestStatus.Loading) as RequestStatus;
}

export function selectTimeSlotsTimes(state: AppState) {
    const dateTimeSlots = oc(selectShopping(state)).timeSlots.dates({});
    const groupId = oc(selectShopping(state)).timeSlots.groupId('undefined');
    const dates = Object.keys(dateTimeSlots);

    if (dates.length === 0) {
        return {};
    }

    const usedSlots = selectTimeSlotsCartSlots(state)[groupId] || {};
    const usedSlots2 = selectOccupiedTimeSlots(state)[groupId] || {};
    const times = _.cloneDeep(dateTimeSlots[dates[0]]);

    for (const d of dates) {
        if (!dateTimeSlots[d]) {
            continue;
        }

        for (const time of Object.keys(dateTimeSlots[d])) {
            if (!times[time]) {
                continue;
            }

            const newAvailable = dateTimeSlots[d][time].available;
            const newOccupied = dateTimeSlots[d][time].occupied +
                oc(usedSlots)[d][time](0) +
                oc(usedSlots2)[d][time](0);

            if ((times[time].available - times[time].occupied) > (newAvailable - newOccupied)) {
                times[time].available = newAvailable;
                times[time].occupied = newOccupied;
            }
        }
    }

    return times;
}

export function selectTimeSlotsType(state: AppState) {
    return oc(selectShopping(state)).timeSlots.type();
}

export function selectTimeSlotTranslations(state: AppState) {
    return oc(selectShopping(state)).timeSlots.translations.eshop({});
}

export function selectTimeSlotsCartSlots(state: AppState) {
    return selectCartItems(state).reduce((acc, item) => {
        if (item.time && item.inclusiveValidity && item.timeSlotGroup) {
            const group = item.timeSlotGroup || 'undefined';

            return item.inclusiveValidity.reduce((iacc, date) => {
                iacc[group] = iacc[group] || {};
                iacc[group][date] = iacc[group][date] || {};
                iacc[group][date][item.time] = iacc[group][date][item.time] || 0;
                iacc[group][date][item.time]++;

                return iacc;
            }, acc);
        }

        return acc;
    }, {});
}

export function selectTimeSlotsDailyTimes(state: AppState) {
    return oc(selectShopping(state)).dayTimeSlots({});
}

export function selectTransactionVouchers(state: AppState) {
    return oc(selectShopping(state)).cart.transactionVouchers([]);
}

// Sagas
function* watchFetchProducts({ payload }: any) {

    if (!payload) {
        payload = yield select(selectSeasons);
    }

    const resp = yield call(api.getProducts, payload);

    if (resp.ok) {
        const { data: products } = resp;
        const variants = yield call(api.getAllProductsVariants, payload);

        for (const product of products) {
            product.variants = variants.data ?
                variants.data.filter(v => v.parents.includes(product._id)) :
                [];
        }

        yield put(fetchProductsActions.success(products));
    }
}

function* watchFetchSeasons() {
    const resp = yield call(api.getSeasons);
    if (resp.ok) {
        const seasons = resp.data.data;
        const activeSeason = seasons.find(season => {
            return season.active === true;
        });
        yield put(fetchSeasonsAction.success(seasons));
        yield call(watchFetchProducts, {payload: seasons});
        yield put(changeSeasonAction(activeSeason));
        yield put(setInitializingAction(false));
    }
}

function* watchFetchBanners() {
    const resp = yield call(api.getBanners);
    if (resp.ok) {
        yield put(
            fetchBannersActions.success(
                resp.data.map(banner => {
                    return {
                        id: banner._id,
                        slug: banner.slug,
                        isExternal: banner.isExternal,
                        ...banner.eshop_banner,
                    };
                })
            )
        );
    }
}

function* watchFetchConfig() {
    const resp = yield call(api.getConfig);
    if (resp.ok && resp.data.length > 0) {
        yield put(fetchConfigActions.success(resp.data[0].value));

        environment.config.dualPrices = resp.data[0].value.hasOwnProperty('dualPrices') ?
            resp.data[0].value.dualPrices :
            environment.config.dualPrices;
    }
}

function* watchFetchCategories() {
    const season = yield select(selectSeason);
    const resp = yield call(api.getSeasonCategories, season);
    if (resp.ok) {
        yield put(fetchCategoriesActions.success(resp.data));
    }
}

function* watchEmailCheck({ payload }: any) {
    const resp = yield call(api.userEmailCount, payload);

    if (resp.ok) {
        yield put(emailValidateActions.success(resp.data));
    }
}

export function* getValidTransactionItems() {
    const cartItems: ProductSelection[] = yield select(selectCartItems);
    const transactionEntries: EntryBase[] = yield select(selectCartTransactionEntries);

    const validItems: ProductSelection[] = [];

    for (const cartItem of cartItems) {
        const entry = transactionEntries.find(entry => entry.uuid === cartItem.id);

        if (!oc(entry).meta.priceValidTo() || moment(oc(entry).meta.priceValidTo()).isAfter(moment())) {
            validItems.push(cartItem);
        } else {
            yield put(removeItemFromCartAction.success(cartItem.id));
        }
    }

    if (validItems.length < cartItems.length) {
        message.warning(i18n.t('dynamicPrice.reservation.expired'), 5);
    }

    return { cartItems: validItems, changed: validItems.length < cartItems.length };
}

function* watchValidateTransaction() {
    const { cartItems }: { cartItems: ProductSelection[] } = yield call(getValidTransactionItems);
    const asyncUserId = yield select(selectAsyncRegisteredId);
    const user: UserProfile | null = yield select(selectUserProfile);

    const logged =
        window.location.pathname === '/shopping/eshop' ||
        window.location.pathname === '/personal/discounts' ||
        window.location.pathname.startsWith('/banners') ||
        window.location.pathname === '/shopping/discount'
            ? true
            : undefined;

    const vouchers: string[] = yield select(selectTransactionVouchers);
    const resp = yield call(
        transactionsApi.dryRun,
        cartItems,
        vouchers,
        !logged,
        oc(user).id() || asyncUserId || undefined
    );

    if (resp.ok) {
        yield put(
            validateTransactionActions.success({
                credit:
                    resp.data.meta && resp.data.meta.account
                        ? Number(resp.data.meta.account.credit.spent)
                        : 0,
                transaction: resp.data,
            })
        );
    } else {
        message.error(i18n.t('unableToValidate'), 5);
        yield put(resetCartAction.request());
        yield put(clearEmailStateAction());
        yield put(resetTemporaryCardsAction());
    }
}

function* watchChangeSeason() {
    yield call(watchFetchCategories);
}

function* watchValidateProductVoucher({ payload: { selection, voucher } }: any) {
    const cartItems: ProductSelection[] = yield select(selectCartItems);

    const updatedCartItems = cartItems.map(item => ({
        ...item,
        voucher: item.id === selection.id ? voucher : item.voucher,
    })) as ProductSelection[];

    const vouchers: string[] = yield select(selectTransactionVouchers);
    const resp = yield call(transactionsApi.dryRun, updatedCartItems, vouchers);

    if (resp.ok) {
        const transaction = resp.data as TransactionBase;
        const entry = transaction.entries.find(e => e.uuid === selection.id && e.voucher === voucher);

        const txVoucher = transaction.entries.find((e) => e.type === EntryType.Voucher && e.amount.club < 0);

        if (txVoucher) {
            if (!vouchers.includes(voucher)) {
                yield put(addTransactionVoucherAction([voucher]));
            }

            yield put(validateProductVoucherAction.success({ needMore: false, voucher: null }));
        } else if (oc(entry).voucherJournal.applied()) {
            yield put(validateProductVoucherAction.success({ needMore: false, voucher }));
        } else if (oc(entry).voucherJournal.partiallyApplied()) {
            yield put(validateProductVoucherAction.success({ needMore: true, voucher }));
        } else {
            yield put(validateProductVoucherAction.failure(oc(entry).voucherJournal.error('general')));
        }
    } else {
        message.error(i18n.t('unableToValidate'), 5);
        yield put(validateProductVoucherAction.failure('general'));
    }
}

function* watchValidateProductSelection({ payload }: any) {
    const cartItems: ProductSelection[] = payload;

    const resp = yield call(transactionsApi.dryRun, cartItems);

    if (resp.ok) {
        yield put(
            validateProductSelectionActions.success(resp.data)
        );
    } else {
        yield put(validateProductSelectionActions.failure(resp.data));
    }
}

function* watchValidateProductSelectionDiscount({ payload }: any) {
    const cartItems: ProductSelection[] = payload.transaction;
    const product = payload.product;

    const resp = yield call(transactionsApi.dryRun, cartItems);

    if (resp.ok) {
        // need total discounted value for credit Discount component
        const withoutCredits = resp.data.entries.reduce((acc, entry) => acc + entry.product.price, 0);
        const lastPrice = resp.data.entries.length > 0
                ? Number(resp.data.entries[resp.data.entries.length - 1].product.price)
                : 0;

        yield put(
            validateProductSelectionActionsDiscount.success({
                transaction: resp.data,
                product: product,
            })
        );
    } else {
        yield put(validateProductSelectionActionsDiscount.failure(resp.data));
    }
}

function* watchValidateSingleVariant({ payload }: any) {
    const cartItems: ProductSelection[] = [
        {
            product: '',
            variant: payload.variant,
            arrival: payload.arrival
        }
    ];

    const resp = yield call(transactionsApi.dryRun, cartItems);

    if (resp.ok && resp.data.entries && resp.data.entries[0]) {
        yield put(
            validateSingleVariantActions.success({
                entry: resp.data.entries[0],
                variant: payload.variant,
            })
        );
    } else {
        yield put(validateProductSelectionActionsDiscount.failure(resp.data));
    }
}

function* watchGetTimeSlots({ payload: { date, groupId, days } }: any) {

    const dates = new Array(days)
        .fill(1)
        .map((d, i) => moment(date).add(i, 'days').format('YYYY-MM-DD'));

    const resp = yield call(api.getTimeSlots, dates, groupId);

    if (resp.ok) {
        yield put(
            getTimeSlotOptionsActions.success({
                status: RequestStatus.Loaded,
                type: resp.data.type,
                translations: resp.data.translations,
                dates: resp.data.dates,
                groupId,
            })
        );
    } else {
        yield put(getTimeSlotOptionsActions.failure());
    }
}

function* watchGetTimeSlotsDaily({ payload }: any) {

    const resp = yield all(
        Object.keys(payload)
            .reduce((acc, groupId) => ({
                ...acc,
                [groupId]: call(api.getTimeSlots, payload[groupId], groupId)
            }), {})
    );

    const data = Object.keys(resp)
        .filter(groupId => resp[groupId].ok)
        .reduce((acc, groupId) => ({
            ...acc,
            ...Object.keys(resp[groupId].data.dates).reduce((acci, date) => ({
                ...acci,
                [`${groupId}|${date}`]: resp[groupId].data.dates[date],
            }), {})
        }), {});

    yield put(getTimeSlotDailyOptionsActions.success(data));
}

export function* eshopSaga() {
    yield all([fork(confirmationSaga), fork(b2bConfirmationSaga), fork(checkoutSaga)]);

    yield takeLatest(EshopActionTypes.ChangeSeason, watchChangeSeason);

    yield takeLatest(
        createActionType(EshopActionTypes.ValidateProductSelection, RequestActionTypes.REQUEST),
        watchValidateProductSelection
    );

    yield takeEvery(
        createActionType(EshopActionTypes.ValidateSingleVariant, RequestActionTypes.REQUEST),
        watchValidateSingleVariant
    );

    yield takeEvery(
        createActionType(
            EshopActionTypes.ValidateProductSelectionDiscount,
            RequestActionTypes.REQUEST
        ),
        watchValidateProductSelectionDiscount
    );

    yield takeLatest(
        createActionType(EshopActionTypes.FetchSeasons, RequestActionTypes.REQUEST),
        watchFetchSeasons
    );

    yield takeLatest(
        createActionType(EshopActionTypes.ValidateProductVoucher, RequestActionTypes.REQUEST),
        watchValidateProductVoucher
    );

    yield takeLatest(
        createActionType(EshopActionTypes.FetchProducts, RequestActionTypes.REQUEST),
        watchFetchProducts
    );

    yield takeLatest(
        createActionType(EshopActionTypes.FetchBanners, RequestActionTypes.REQUEST),
        watchFetchBanners
    );

    yield takeLatest(
        createActionType(EshopActionTypes.FetchConfig, RequestActionTypes.REQUEST),
        watchFetchConfig
    );

    yield takeLatest(
        createActionType(EshopActionTypes.FetchCategories, RequestActionTypes.REQUEST),
        watchFetchCategories
    );

    yield takeLatest(
        createActionType(OrderActionTypes.EmailValidate, RequestActionTypes.REQUEST),
        watchEmailCheck
    );
    yield takeLatest(
        [
            createActionType(CartActionTypes.ValidateTransaction, RequestActionTypes.REQUEST),
            CartActionTypes.SetCartItems,
            createActionType(CartActionTypes.RemoveFromCart, RequestActionTypes.SUCCESS),
            CartActionTypes.UpdateItemAssigment,
            CartActionTypes.UpdateItemVoucher,
            CartActionTypes.OpenCart,
            CartActionTypes.UpdateEntryTimeSlot,
            CartActionTypes.RemoveTransactionVoucher,
        ],
        watchValidateTransaction
    );

    yield takeLatest(
        createActionType(EshopActionTypes.GetTimeSlotOptions, RequestActionTypes.REQUEST),
        watchGetTimeSlots
    );

    yield takeLatest(
        createActionType(EshopActionTypes.GetTimeSlotDailyOptions, RequestActionTypes.REQUEST),
        watchGetTimeSlotsDaily
    );
}

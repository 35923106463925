import React, {ReactNode, useState} from 'react';
import {LocalizedString} from '../index';
import {Collapse, Icon} from 'antd';
import {i18n} from '../../services/i18n';
import {Product} from '../../models/Product';
import DropdownMenu from '../DropdownMenu';
import cn from 'classnames';
import {Season} from '../../models/AppState';

interface Props {
    category: Product;
    categories: Product[];
    onBack: () => void;
    actions?: (category: Product) => ReactNode;
    onSelect: (category: Product) => void;
    season: Season;
}

const  CategoryDetail = ({ category, categories,  onBack, onSelect, actions, season }: Props) => {
    const [isVisible, setIsVisible] = useState(false);

    const handleCategoryClick = (category) => {
        onSelect(category);
        setIsVisible(false);
    };

    const menuItems = categories.map((category) => ({
        title: <LocalizedString value={category.name} />,
        onClick: () => handleCategoryClick(category),
    }));

    return (
        <div className="category-detail">
            <div className={cn('category-detail-header', {'category-detail-header--summer': season.type === 'summer', 'category-detail-header--winter': season.type === 'winter'})}>

                <div className="back-button" onClick={onBack}>
                    <Icon type="left"/>
                    <p>{i18n.t('categoriesList.back', {defaultValue: 'Späť'})} </p>
                </div>

                <DropdownMenu
                    menuItems={menuItems}
                    visible={isVisible}
                    onVisibleChange={(visible) => setIsVisible(!visible)}
                >
                    <LocalizedString value={category.name}/>
                </DropdownMenu>

            </div>
            {category && LocalizedString.format(category.description) &&
                <div className="category-detail-description">
                    <p>
                        <LocalizedString html={category.description}/>
                    </p>
                </div>
            }
            {category.descriptionBlocks && category.descriptionBlocks[i18n.language].length > 0 && (
                <div className="category-detail-description">
                    <Collapse>
                        {category.descriptionBlocks[i18n.language].map((block, index) => (
                            <Collapse.Panel
                                header={<div className="category-detail-description-title"><Icon type="info-circle" theme="filled" /><p>{block.title}</p></div> }
                                key={String(index)}
                                className="category-detail-description-panel"
                            >
                                {block.content && (
                                    <div
                                        className="category-detail-description-content"
                                        dangerouslySetInnerHTML={{ __html: block.content }}
                                    />
                                )}
                            </Collapse.Panel>
                        ))}
                    </Collapse>
                </div>
            )}

            {actions && category && (
                <div className="tabs-children">{actions(category)}</div>
            )}
        </div>
    );
};
export default CategoryDetail;

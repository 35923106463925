import React from 'react';
import {Icon, Modal} from 'antd';
import {Moment} from 'moment';
import CalendarDatePicker from '../CalendarDatePicker';
import {ModalSizes} from '../../../constants/modal';
import {
    VariantsSelection
} from '../../../../features/shopping/features/eshop/components/ProductSelect/components/VariantsDropdown';
import BodyClassName from 'react-body-classname';
import cn from 'classnames';
import {Drawer} from '../../index';
import {useIsMobileWide} from '../../../hooks/useIsMobileWide';
import PriceCalendarCell from '../CalendarDatePicker/components/PriceCalendarCell';
import CalendarCell from '../CalendarDatePicker/components/CalendarCell';
import {
    isCalculationDisabled
} from '../../../../features/shopping/features/eshop/components/ProductSelect/components/DateSelectCarousel/useDatePrices';
import { i18n } from '../../../services/i18n';

interface DatePickerInputProps {
    label?: string;
    placeholder?: string;
    value?: Moment | null;
    onChange?: (date: Moment) => void;
    modalTitle?: string;
    open?: boolean;
    onOpenChange?: ((status: boolean) => void);
    selectedVariants?: VariantsSelection[];
    disabledDate?: ((current: (Moment | null)) => boolean);
    disabled?: boolean;
    cellType?: 'CELL' | 'PRICE_CELL';
}

const DatePickerInput = ({
    open = false,
    selectedVariants,
    label,
    placeholder,
    value,
    onChange,
    modalTitle,
    disabledDate,
    disabled = false,
    cellType,
    onOpenChange
}: DatePickerInputProps) => {
    const isMobile = useIsMobileWide();

    const calculationDisabled = isCalculationDisabled(selectedVariants);

    const handleOpenModal = () => {
        if (!disabled) {
            onOpenChange && onOpenChange(true);
        }
    };

    const handleCloseModal = () => {
        onOpenChange && onOpenChange(false);
    };

    const handleDateSelect = (date: any) => {
        if (onChange) {
            onChange(date);
        }
        onOpenChange && onOpenChange(false);
    };

    const renderPriceCell = (date, currentMonth) => (
        <PriceCalendarCell currentMonth={currentMonth} disabledDate={disabledDate} selectedDate={value} date={date} selectedVariants={selectedVariants || []} />
    );

    const renderCell = (date, currentMonth) => (
        <CalendarCell currentMonth={currentMonth} disabledDate={disabledDate} selectedDate={value} date={date} />
    );

    const getCalendarModalContent = () => {
        return (
            <div className="calendar-modal">
                <CalendarDatePicker
                    onCancel={handleCloseModal}
                    onDateSelect={handleDateSelect}
                    disabledDate={disabledDate}
                    selectedDate={value}
                    renderCell={cellType === 'PRICE_CELL' ? renderPriceCell : renderCell}
                    footer={calculationDisabled ? i18n.t('ticketSelect.calculationLimit') : undefined}
                />
            </div>
        );
    };

    const renderMobileOverlay = () => (
        <BodyClassName className={cn({ 'blocked-scroll': open })}>
            <Drawer
                visible={open}
                onClose={handleCloseModal}
                title={placeholder}
                placement="bottom"
                type="dropdownMirror"
                height="auto"
                closable
            >
                {getCalendarModalContent()}
            </Drawer>
        </BodyClassName>
    );

    return (
        <div className="custom-calendar-input">
            {label && <label className="input-label">{label}</label>}

            <div  className={cn('input-wrapper', { disabled })} onClick={handleOpenModal}>
                <div className="custom-input">
                    {!disabled && value ? value.format('DD.MM.YYYY') : placeholder}
                </div>
                <div className="calendar-icon">
                    <Icon type="calendar"/>
                </div>
            </div>


            {isMobile && renderMobileOverlay()}
            {!isMobile && open && (
                <Modal
                    title={modalTitle}
                    visible={true}
                    onCancel={handleCloseModal}
                    width={ModalSizes.default}
                    footer={null}
                    closable
                >
                    {getCalendarModalContent()}
                </Modal>
            )}
        </div>
    );
};

export default DatePickerInput;

import {Col, Row} from 'antd';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import {oc} from 'ts-optchain';

import {Select} from '../../../../../../../../common/components';
import {Product} from '../../../../../../../../common/models/Product';
import {i18n} from '../../../../../../../../common/services/i18n';
import {SelectLabel} from '../SelectLabel';
import {VariantsDropdown, VariantsSelection} from '../VariantsDropdown';
import {Season} from '../../../../../../../../common/models/AppState';
import {environment} from '../../../../../../../../environments/environment';
import {
    isProductAndAnyVariantVisible,
    isProductValidAtDate
} from '../../../../../../../../common/utils/visibility-helpers';
import {EntryBase, TransactionDryRunBenefit} from '../../../../../../../../common/models/NewTransaction';
import CalendarDatePickerInput from '../../../../../../../../common/components/Form/CalendarDatePickerInput';

interface Props {
    season?: Season;
    category?: Product;
    product: string;
    arrival: string;
    products: Product[];
    badges: TransactionDryRunBenefit[];
    entries: EntryBase[];
    singleVariants: Record<string, EntryBase>;
    selectedVariants: VariantsSelection[];

    setVariants(values: VariantsSelection[]): void;
    setProduct(product: string): void;
    validateSingleVariants(): void;
    setDate(date): void;
    onVariantsClose?: () => void;
}

function FirstStep(props: Props) {
    const [datepickerVisibility, setDatePickerVisibility] = useState<boolean>(false);
    const [variantsDropdownVisibility, setVariantsDropdownVisibility] = useState<boolean>(false);

    const activeProduct = props.products.find(item => item._id === props.product) as Product;
    const hasTimeSlots = !!oc(activeProduct).hasTimeSlots();
    const metaDateView = oc(activeProduct).meta.dateView('DatePicker');

    useEffect(() => {
        setDatePickerVisibility(false);
        setVariantsDropdownVisibility(false);
    }, [props.product]);

    function handleProductSelect(value) {
        if (value) {
            props.setProduct(value);

            const product = props.products.find(item => item._id === value) as Product;

            if (oc(product).meta.dateView('DatePicker') !== 'DateStrip' && !oc(product).hasTimeSlots()) {
                setDatePickerVisibility(true);
                setVariantsDropdownVisibility(false);
            } else {
                setVariantsDropdownVisibility(true);
                setDatePickerVisibility(false);
            }
        }
    }

    function handleDateSelect(value) {
        if (value) {
            props.setDate(value);

            setDatePickerVisibility(false);
            setVariantsDropdownVisibility(true);
        }
    }

    function handleVariantSelect(value) {
        props.setVariants(value);
    }

    function getMomentValue() {
        if (!props.product) { return undefined; }

        const currentValue = moment(props.arrival).isValid() ? moment(props.arrival) : null;

        if (!currentValue || !isProductValidAtDate(activeProduct, currentValue)) {
            let closestValidDate = moment();
            const maxRange = moment().add(1, 'year');

            while (!isProductValidAtDate(activeProduct, closestValidDate)) {
                closestValidDate = closestValidDate.add(1, 'day');
                if (closestValidDate.isAfter(maxRange)) {
                    return undefined;
                }
            }

            return closestValidDate;
        }

        return currentValue;
    }

    function getAllowedRanges(current) {
        return environment.getGlobalDisabledDates(current) || !isProductValidAtDate(activeProduct, current);
    }

    function getSelectOptions() {
        return props.products
            .filter(product =>
                oc(product).parents([]).includes(oc(props.category)._id('')) &&
                oc(product).season([]).includes(oc(props.season)._id('')) &&
                isProductAndAnyVariantVisible(product)
            )
            .sort(
                (a, b) =>
                    oc(a).placement.ranks({})[oc(props.category)._id('')].rank -
                    oc(b).placement.ranks({})[oc(props.category)._id('')].rank
            )
            .map(product => ({
                label: <SelectLabel product={product}/>,
                value: product._id,
            }));
    }


    const renderVariantsDropdown = (disabled = false) => (
        <VariantsDropdown
            season={props.season}
            badges={props.badges}
            product={activeProduct}
            onChange={handleVariantSelect}
            entries={props.entries}
            singleVariants={props.singleVariants}
            disabled={disabled}
            open={variantsDropdownVisibility}
            onOpenChange={setVariantsDropdownVisibility}
            onDropdownOpen={props.validateSingleVariants}
            onClose={props.onVariantsClose}
        />
    );

    const renderDatePicker = (withPrices = false) => (
        <CalendarDatePickerInput
            selectedVariants={withPrices ? props.selectedVariants : undefined}
            open={datepickerVisibility}
            onOpenChange={setDatePickerVisibility}
            disabledDate={getAllowedRanges}
            disabled={!props.product && !activeProduct}
            placeholder={i18n.t('ticketSelect.date.placeholder')}
            label={i18n.t('ticketSelect.date.label')}
            value={getMomentValue()}
            onChange={handleDateSelect}
            cellType={withPrices ? 'PRICE_CELL' : 'CELL'}
            modalTitle={i18n.t('ticketSelect.date.label')}
        />
    );

    const renderTicketSelect = () => (
        <Select
            onChange={handleProductSelect}
            placeholder={i18n.t('ticketSelect.product.placeholder')}
            label={i18n.t('ticketSelect.product.label')}
            value={props.product}
            options={props.category ? getSelectOptions() : []}
            style={{ width: '100%' }}
            notFoundContent={i18n.t('ticketSelect.product.empty')}
        />
    );

    const colSize = metaDateView === 'DateStrip' || hasTimeSlots ? 12 : 8;

    return (
        <Row gutter={16}>
            <Col xs={24} md={colSize}>
                {renderTicketSelect()}
            </Col>
            {metaDateView !== 'DateStrip' && !hasTimeSlots && (
                <Col xs={24} md={colSize}>
                    {renderDatePicker(metaDateView === 'Calendar')}
                </Col>
            )}
            <Col xs={24} md={colSize}>
                {renderVariantsDropdown(!props.product)}
            </Col>
        </Row>
    );
}

export default FirstStep;

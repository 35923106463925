import React, { useState } from 'react';

interface IconProps {
    src: string;
    alt: string;
    size?: number;
}

const DynamicIcon = ({ src, alt, size = 26 }: IconProps) => {
    const [isImageError, setImageError] = useState(false);

    return isImageError ? null : (
        <img
            src={src}
            alt={alt}
            onError={() => setImageError(true)}
            style={{
                width: `${size}px`,
                height: `${size}px`,
                objectFit: 'fill',
            }}
        />
    );
};

export default DynamicIcon;

import React, { ReactNode, useEffect, useState } from 'react';
import {Calendar, Icon, Spin} from 'antd';
import moment, {Moment} from 'moment';
import {Button} from '../../index';
import {i18n} from '../../../services/i18n';
import {
    useQueriesLoading
} from '../../../../features/shopping/features/eshop/components/ProductSelect/components/DateSelectCarousel/useQueriesLoading';

interface Props {
    onCancel?: () => void;
    onDateSelect?: (date: Moment) => void;
    selectedDate?: Moment | null;
    disabledDate?: ((current: (Moment | null)) => boolean);
    renderCell?: (date: Moment, currentMonth: Moment, props: Props) => ReactNode;
    footer?: ReactNode | ReactNode[];
}

const CalendarDatePicker = (props: Props) => {
    const [currentMonth, setCurrentMonth] = useState<Moment>(moment());

    const pricesQueryLoading = useQueriesLoading(['date-prices']);

    const handleDateSelect = (date) => {
        if (props.disabledDate && props.disabledDate(date)) {
            return;
        }
        if (props.onDateSelect) {
            props.onDateSelect(date);
        }
        setCurrentMonth(date);
    };
    const handlePanelChange = (value) => {
        setCurrentMonth(value);
    };

    useEffect(() => {
        setCurrentMonth(props.selectedDate ? moment(props.selectedDate) : moment());
    }, [props.selectedDate]);

    return (
        <div className="calendar-date-picker-modal">
            <div className="calendar-container">
                { pricesQueryLoading && <Spin className="spinner-loading" size="large" /> }
                <Calendar
                    value={currentMonth}
                    className={pricesQueryLoading ?  'calendar-hidden' : 'calendar-visible'}
                    onPanelChange={handlePanelChange}
                    headerRender={({ value, onChange }) => (
                        <div className="custom-calendar-header">
                            <Icon type="left" onClick={() => onChange &&  onChange(value.clone().subtract(1, 'month'))} />
                            <p>{value.format('MMMM YYYY')}</p>
                            <Icon type="right" onClick={() => onChange &&  onChange(value.clone().add(1, 'month'))} />
                        </div>
                    )}
                    fullscreen={false}
                    onSelect={handleDateSelect}
                    dateFullCellRender={(date) =>
                        props.renderCell && props.renderCell(date, currentMonth, props)
                    }
                />
            </div>
            {props.footer && (
                <div className="footer-container">
                    {props.footer}
                </div>
            )}
            {props.onCancel && (
                <div className="calendar-footer">
                    <Button type="primary" onClick={props.onCancel}>
                        {i18n.t('addVoucherModal.cancelButton')}
                    </Button>
                </div>
            )}
        </div>

    );
};

export default CalendarDatePicker;

import React, {FunctionComponent, useState} from 'react';
import {environment} from '../../../environments/environment';
import {Season} from '../../models/AppState';
import {Product} from '../../models/Product';
import Hero from '../Hero';
import DynamicImg from '../DynamicImg';
import CategoryDetail from '../CategoryDetail';
import CategoriesList from '../CategoriesList';

interface Props {
    categories: Product[];
    season: Season;
    children(product: Product);
}

// TODO-d rename to category selector
const CatgoriesTabs: FunctionComponent<Props> = ({ categories: tabs, children, season }) => {
    const [category, setCategory] = useState<Product | undefined>(undefined);

    const handleBack = () => {
        setCategory(undefined);
    };

    const handleSelectCategory = (category: any) => {
        setCategory(category);
    };

    function getCategoryImg() {
        if (category) {
            return (
                <DynamicImg
                    key={category._id}
                    srcs={[
                        `${environment.mediaUrl}/category-eshop/${category}`,
                        `${environment.mediaUrl}/static/eshop/category.season-${season.type}`,
                        `${environment.mediaUrl}/static/eshop/category`,
                    ]}
                    alt="category"
                    className="categories-tabs__image"
                />
            );
        }

        return (
            <DynamicImg
                srcs={[
                    `${environment.mediaUrl}/static/eshop/category.season-${season.type}`,
                    `${environment.mediaUrl}/static/eshop/category`,
                ]}
                alt="category"
                className="categories-tabs__image"
            />
        );
    }

    return (
        <Hero img={getCategoryImg()}>
            <div className="category-selector">
                {typeof category !== 'undefined' ? (
                    <CategoryDetail categories={tabs} category={category} onBack={handleBack} onSelect={handleSelectCategory} actions={children} season={season}/>
                ) : (
                    <CategoriesList categories={tabs} onSelect={handleSelectCategory} season={season}/>
                )}
            </div>
        </Hero>
    );
};

export default CatgoriesTabs;

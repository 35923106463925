import {Col, Row} from 'antd';
import moment from 'moment';
import React, {Fragment, useEffect, useState} from 'react';
import {oc} from 'ts-optchain';
import uuid from 'uuid/v4';

import {Button, LocalizedString, Price, ProductValidity, TotalPrice} from '../../../../../../common/components';
import {ProductSelection} from '../../../../../../common/models/Cart';
import {Product} from '../../../../../../common/models/Product';
import {i18n} from '../../../../../../common/services/i18n';
import {VariantsSelection} from './components/VariantsDropdown';
import {Season} from '../../../../../../common/models/AppState';
import {TimeSlotType} from '../../../../ducks';
import TimeCarousel from './components/TimeCarousel';
import FirstStep from './components/FirstStep';
import {environment} from '../../../../../../environments/environment';
import {
    findNextEnabledDate,
    isProductAndAnyVariantValidAtDate,
    isProductAndSelectionValidAtDate,
} from '../../../../../../common/utils/visibility-helpers';
import {EntryBase, TransactionDryRunBenefit} from '../../../../../../common/models/NewTransaction';

interface Props {
    season?: Season;
    category?: Product;
    products: Product[];
    total: number;
    totalDiscounted: number;
    badges: TransactionDryRunBenefit[];
    entries: EntryBase[];
    singleVariants: Record<string, EntryBase>;
    onAddToCart: (items: ProductSelection[]) => void;
    onSelectionValidate: (items: ProductSelection[]) => void;
    onSelectionValidateReset: () => void;
    onSingleVariantsValidate: (product: Product, arrival: string) => void;
}

function ProductSelect(props: Props) {
    const [product, setProduct] = useState<string>('');
    const [arrival, setDate] = useState<string>('');
    const [timeSlotType, setTimeSlotType] = useState<TimeSlotType | undefined>(undefined);
    const [time, setTime] = useState<string>('');
    const [variants, setVariants] = useState<VariantsSelection[]>([]);
    const [lastFetchProductArrival, setLastFetchProductArrival] = useState<{product: string; arrival: string}>({product: '', arrival: ''});

    const activeProduct = props.products.find(item => item._id === product) as Product;
    const isTimeslot = !product ? !!props.category && !!props.category.hasTimeSlots : !!oc(activeProduct).hasTimeSlots();
    const isTimeSlotBase = isTimeslot || oc(activeProduct).meta.dateView('DatePicker') === 'DateStrip';

    useEffect(
        () => {
            if (arrival) {
                const selection = selectProductSelection();

                if (selection.length) {
                    props.onSelectionValidate(selection);
                }
            }
        },
        [variants, arrival, time]
    );

    useEffect(
        () => {
            setTime('');
            setTimeSlotType(undefined);
        },
        [arrival]
    );

    useEffect(
        () => {
            if (!arrival || getAllowedRanges(moment(arrival))) {
                const d = findNextEnabledDate(getAllowedRanges, moment(oc(activeProduct).validity.to()));
                const date = d ? d.startOf('day').format() : '';

                if (date !== arrival) {
                    setDate(date);
                    setTime('');
                    setTimeSlotType(undefined);
                }
            }
        },
        [variants]
    );

    useEffect(
        () => {
            handleReset();
        },
        [props.category]
    );

    useEffect(
        () => {
            props.onSelectionValidateReset();

            setVariants([]);
        },
        [product]
    );

    function getAllowedRanges(current) {
        if (variants.length === 0) {
            return !isProductAndAnyVariantValidAtDate(activeProduct, current);
        } else {
            return !isProductAndSelectionValidAtDate(
                activeProduct,
                variants.filter(v => v.count).map(v => v.id),
                current
            );
        }
    }

    const validateSingleVariants = () => {
        if (!arrival) {
            return;
        }

        const {product: oldProduct, arrival: oldArrival} = lastFetchProductArrival;

        if (arrival !== oldArrival || product !== oldProduct) {
            setLastFetchProductArrival({ product, arrival });
            props.onSingleVariantsValidate(activeProduct, arrival);
        }
    };

    function selectProductSelection(): ProductSelection[] {
        const selectedProd = activeProduct;

        return variants.reduce<ProductSelection[]>((a, c) => {
            const arr = new Array(c.count).fill(0);
            const dayCount = oc(selectedProd).meta.tokenValidityUnit() === 'days'
                ? Number(oc(selectedProd).meta.tokenValidityAmount('1'))
                : 1;
            const inclusiveValidity = new Array(dayCount)
                .fill(1)
                .map((_, i) => moment(arrival).add(i, 'days').format('YYYY-MM-DD'));

            const v = arr.map(() => ({
                id: uuid(),
                createdAt: new Date().toISOString(),
                variant: c.id,
                product,
                arrival,
                inclusiveValidity,
                time,
                timeEnd: timeSlotType === TimeSlotType.range && time
                    ? moment(time, 'HH:mm')
                        .add(30, 'minutes')
                        .format('HH:mm')
                    : undefined,
                timeSlotType,
                timeSlotGroup: oc(activeProduct).timeSlotGroup(''),
            }));

            a.push(...v);

            return a;
        }, []);
    }

    function handleReset() {
        setProduct('');
        setDate('');
        setTimeSlotType(undefined);
        setVariants([]);
        setLastFetchProductArrival({ product: '', arrival: '' });
    }

    function handleAddToCartClick() {
        const data = selectProductSelection();
        props.onAddToCart && props.onAddToCart(data);
        handleReset();
    }

    function renderItemValidity() {
        if ((oc(activeProduct).tags([]) as any).includes('seasonal')) {
            return i18n.t('cartItem.seasonal');
        } else if (arrival === '') {
            return '';
        } else {
            return (
                <Fragment>
                    {i18n.t('checkout.validity')}{' '}
                    <ProductValidity
                        from={arrival}
                        unit={oc(activeProduct).meta.tokenValidityUnit('')}
                        validityAmount={Number(oc(activeProduct).meta.tokenValidityAmount() || 1)}
                        time={timeSlotType === TimeSlotType.range ? time : undefined}
                    />
                </Fragment>
            );
        }
    }

    function renderInfo() {
        return (
            <Fragment>
                <Row className="info-row" type="flex" align="bottom">
                    <Col xs={24}>

                        <p>
                            <LocalizedString value={activeProduct.descriptionDetail} />
                        </p>
                    </Col>
                </Row>
                <Row className="info-row" type="flex" align="bottom">
                    <Col xs={24} >
                        {props.total && <TotalPrice
                            type="default"
                            pricePosition="bottom"
                            withDiscount={<Price value={props.totalDiscounted}/>}
                            badges={props.badges}
                            withoutDiscount={
                                <Fragment>
                                    {i18n.t('ticketSelect.priceWithoutDiscount')}{' '}
                                    <Price value={props.total}/>
                                </Fragment>
                            }
                            displayDiscount={props.totalDiscounted !== props.total}
                        />}
                    </Col>
                </Row>
            </Fragment>

        );
    }

    function isProductAndSelectedEntriesValid() {
        return isProductAndSelectionValidAtDate(activeProduct, props.entries.map(e => e.product.variantId), arrival);
    }

    function renderSecondStep() {
        return (
            <Fragment>
                {renderInfo()}
                <Row gutter={16} type="flex" align="bottom">
                    <Col xs={24} md={24}>
                        <Row type="flex" justify="start"  align="middle">
                            <Col xs={24} md={6}>
                            <Button
                                disabled={
                                    !arrival ||
                                    !props.entries.length ||
                                    (isTimeslot && !time) ||
                                    environment.getGlobalDisabledDates(moment(arrival)) ||
                                    !isProductAndSelectedEntriesValid()
                                }
                                onClick={handleAddToCartClick}
                                type="success"
                                size="small"
                                block
                            >
                                {i18n.t('ticketSelect.addToCart')}
                            </Button>
                            </Col>
                            <Col xs={24} md={10}>
                                <p className="item-validity">{renderItemValidity()}</p>
                            </Col>

                        </Row>

                    </Col>
                </Row>
            </Fragment>
        );
    }

    return (
        <div className="ticket-select">
            <FirstStep
                season={props.season}
                category={props.category}
                products={props.products}
                badges={props.badges}
                entries={props.entries}
                selectedVariants={variants}
                singleVariants={props.singleVariants}
                product={product}
                arrival={arrival}
                setVariants={setVariants}
                setProduct={setProduct}
                setDate={setDate}
                validateSingleVariants={validateSingleVariants}
            />
            {isTimeSlotBase && !!variants.length &&
                <div>
                    <TimeCarousel
                        product={activeProduct}
                        selectedVariants={variants}
                        arrival={arrival}
                        setDate={setDate}
                        time={time}
                        variantCount={variants.reduce((acc, v) => acc + v.count, 0)}
                        setTime={setTime}
                        setTimeSlotType={setTimeSlotType}
                        timeSlotGroup={oc(activeProduct).timeSlotGroup('')}
                        onlyDateRow={!isTimeslot}
                    />
                </div>

            }
            {((!isTimeslot && !!arrival) || (isTimeslot && !!variants.length)) && activeProduct &&
                renderSecondStep()
            }
        </div>
    );
}

export default ProductSelect;

import { Col, Row } from 'antd';
import React, { Fragment, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { oc } from 'ts-optchain';
import { withRouter, RouteComponentProps } from 'react-router';
import uuid from 'uuid/v4';

import { ModalSizes } from '../../../../common/constants/modal';
import { AppState } from '../../../../common/models/AppState';
import { i18n } from '../../../../common/services/i18n';
import {
    CodeVerifcationTypes,
    resetCodeVerificationAction,
    selectCodeVerificationApplied,
    selectCodeVerificationType,
} from '../../../code/ducks';
import {
    Carousel,
    Discount,
    DiscountLink,
    Layout,
    Modal, Price,
    ReferenceWrapper,
    SideWizard,
} from '../.././../../common/components';
import {
    DiscountsState,
    fetchDiscountsAction,
    selectDiscounts,
    selectUserCredit,
    selectCartCredit,
} from '../../ducks';
import { categories } from './categories';
import VoucherVerifyContainer from './containers/VoucherVerify';
import { Product } from '../../../../common/models/Product';
import {
    fetchProductsActions,
    selectDiscountPrices,
    selectProducts,
    validateProductSelectionActionsDiscount,
} from '../../../shopping/ducks';
import { Benefit, CreditBenefit, TotalValue, VoucherBenefit } from '../../../../common/models/Benefit';
import DatePickerModal, { DatePickerChangeValues } from '../../../../common/components/Form/DatePickerModal';
import { ProductSelection, ProductSelectionPopulated } from '../../../../common/models/Cart';
import {
    openCartAction,
    selectCartPopulatedItems,
    setCartItemsAction,
} from '../../../shopping/ducks/cart';
import { VariantsSelection } from '../../../shopping/features/eshop/components/ProductSelect/components/VariantsDropdown';
import { createRefs } from '../../../../common/components/ReferenceWrapper';
import { isProductAndAnyVariantVisible } from '../../../../common/utils/visibility-helpers';
import PartnerBenefit from '../../../../common/components/PartnerBenefit';
import VoucherDiscount from '../../../../common/components/VoucherDiscount';
import CreditDiscount from '../../../../common/components/CreditDiscount';

export const DiscountsComponent = (props: DispatchToProps & StateToProps & RouteComponentProps) => {
    const [visible, setVisible] = useState(false);
    const [selectedProd, setSelectedProd] = useState<Product | undefined>(undefined);
    const [selectedVariant, setSelectedVariant] = useState<{ [name: string]: VariantsSelection[] }>({});
    const [selectedBenefit, setSelectedBenefit] = useState<{ product: string; benefit: string, type: string }>({
        product: '',
        benefit: '',
        type: '',
    });
    const [refs, setRefs] = useState({});
    const [reset, setReset] = useState(false);

    useEffect(() => {
        props.fetchDiscounts();
        props.fetchProducts();
    }, []);

    useEffect(
        () => {
            setRefs(
                createRefs(filteredBenefitCategories())
            );
        },
        [props.discounts]
    );

    useEffect(
        () => {
            if (
                (
                    props.isAppliedFromCode &&
                    props.verificationType === CodeVerifcationTypes.FromLinkRegister
                ) ||
                props.verificationType === CodeVerifcationTypes.FromLink
            ) {
                if (props.verificationType === CodeVerifcationTypes.FromLink) {
                    Modal.info({
                        title: i18n.t('voucherVerifyModal.success.title'),
                        content: <p>{i18n.t('voucherVerifyModal.success.content')}</p>,
                        okText: i18n.t('voucherVerifyModal.success.button'),
                        okButtonProps: { block: true, type: 'default' },
                        width: ModalSizes.default,
                        onOk: () => {
                            props.resetApliedFromCode();
                        },
                    });
                }

                if (props.verificationType === CodeVerifcationTypes.FromLinkRegister) {
                    Modal.info({
                        title: i18n.t('appliedVoucherModal.title'),
                        content: <p>{i18n.t('appliedVoucherModal.content')}</p>,
                        okText: i18n.t('appliedVoucherModal.button'),
                        okButtonProps: { block: true, type: 'default' },
                        width: ModalSizes.default,
                        onOk: () => {
                            props.resetApliedFromCode();
                        },
                    });
                }
            }
        },
        [props.isAppliedFromCode]
    );

    useEffect(
        () => {
            if (reset) {
                setReset(false);
            }
        },
        [reset]
    );

    function filteredBenefitCategories() {
        return categories.filter(c =>
            (c.key === 'voucher' && oc(props.discounts).voucher([]).some(showVoucherBenefit)) ||
            (c.key === 'credit' && oc(props.discounts).credit([]).some(showCreditBenefit)) ||
            (!['voucher', 'credit'].includes(c.key) && oc(props.discounts)[c.key]([]).some(isBenefitActive))
        );
    }

    function getTxPayload(selection: VariantsSelection[], productId: string, benefitId: string, datePickerData?: DatePickerChangeValues) {
        const transactionPayload: ProductSelection[] = [];

        for (const variant of selection || []) {
            for (let i = 0; i < variant.count; i++) {
                transactionPayload.push({
                    id: uuid(),
                    variant: variant.id,
                    product: productId,
                    ...(datePickerData || {}),
                    arrival: oc(datePickerData).arrival(new Date().toISOString()),
                    voucher: benefitId,
                });
            }
        }

        return transactionPayload;
    }

    function handleModalVisiblityChange(visibility: boolean) {
        setVisible(visibility);

        if (!visibility) {
            handleDateChange({ arrival: '', inclusiveValidity: [], time: '' });
        }
    }

    function handleAddToCart(date: DatePickerChangeValues) {
        props.setCartItems && props.setCartItems(
            getTxPayload(
                selectedVariant[selectedBenefit.benefit + selectedBenefit.product],
                selectedBenefit.product,
                selectedBenefit.benefit,
                date
            )
        );
        props.openCart && props.openCart();
        setReset(true);
    }

    function getSelectedPrice() {

        const index = selectedBenefit.benefit + selectedBenefit.product;

        return {
            discounted: oc(props.total)[index].discounted(0),
            price: selectedBenefit.type !== 'credit'
                ? oc(props.total)[index].price(0)
                : oc(props.total)[index].withoutCredits(0),
            credit: selectedBenefit.type === 'credit',
        };
    }

    function handleDateChange(value: DatePickerChangeValues) {

        if (!selectedProd) {
            return;
        }

        props.validateSelection({
            transaction: getTxPayload(
                selectedVariant[selectedBenefit.benefit + selectedBenefit.product],
                selectedProd._id,
                selectedBenefit.benefit,
                value
            ),
            product: selectedBenefit.benefit + selectedProd._id,
        });
    }

    function isBenefitActive(benefit: Benefit) {
        return oc(benefit).status() !== 'inactive';
    }

    function getHeaderContent(type: string) {
        if (type === 'credit') {
            return (
                <Fragment>
                    <p>{i18n.t(`discounts.credit.detail.subheader`)}</p>
                    <h3>
                        <Price value={getAvailableCredits()}/>
                    </h3>
                </Fragment>
            );
        }

        return undefined;
    }

    function getAvailableCredits() {
        return Math.max(props.credit - props.creditInCart, 0);
    }

    const getProduct = (benefit: CreditBenefit) => {
        return props.products.find(product => benefit.product === product._id && !!product.variants);
    };

    const showVoucherBenefit = (benefit: VoucherBenefit) => {
        const products = props.products.filter(p => benefit.products.includes(p._id) && !!p.variants);

        return (
            !props.cartItems.some(ci => oc(ci).voucher() === benefit.tokenId) &&
            products.length > 0 &&
            products.some(p => isProductAndAnyVariantVisible(p))
        );
    };

    const showCreditBenefit = (benefit: CreditBenefit) => {
        const product = getProduct(benefit);

        return !!product && isProductAndAnyVariantVisible(product);
    };

    const handleAddVoucherToCart = (benefit: VoucherBenefit | CreditBenefit, type: string) => (selection: VariantsSelection[], product: Product) => {
        if (selection.length > 0 && selection.some(v => v.count > 0)) {
            setSelectedBenefit({ benefit: benefit.tokenId, product: product._id, type });
            setSelectedProd(product);
            setSelectedVariant({ ...selectedVariant, [benefit.tokenId + product._id]: selection });
            handleModalVisiblityChange(true);
        }
    };

    const handleSelectionUpdate = (benefit: CreditBenefit) => (selection: VariantsSelection[], product: Product) => {
        props.validateSelection({
            transaction: getTxPayload(selection, product._id, benefit.tokenId),
            product: benefit.tokenId + product._id,
        });
    };

    return (
        <Layout.Content>
            <DatePickerModal
                visible={visible}
                onCancel={handleModalVisiblityChange}
                onConfirm={handleAddToCart}
                onChange={handleDateChange}
                product={selectedProd}
                variantSelection={
                    oc(selectedVariant)[selectedBenefit.benefit + selectedBenefit.product]([]).filter(v => v.count > 0)
                }
                price={getSelectedPrice()}
            />
            <Layout.Container>
                <Row className="discounts">
                    <Col xs={0} md={6} className="orders__left">
                        {filteredBenefitCategories().length > 0 && (
                            <SideWizard
                                refs={refs}
                                title={i18n.t('discounts.side.title')}
                                items={filteredBenefitCategories()
                                    .map(({ title, description }) => ({
                                        title: i18n.t(title),
                                        description: i18n.t(description),
                                    }))}
                            />
                        )}
                    </Col>
                    <Col xs={24} md={18} className="orders__right">
                        {
                            props.verificationType !== CodeVerifcationTypes.FromLink &&
                            props.verificationType !== CodeVerifcationTypes.FromLinkRegister &&
                            <VoucherVerifyContainer/>
                        }

                        {filteredBenefitCategories()
                            .map((c, i) =>
                                <ReferenceWrapper reference={refs[i]} key={i}>
                                    <Carousel
                                        title={i18n.t(c.title)}
                                        description={i18n.t(c.description)}
                                        headerContent={getHeaderContent(c.key)}
                                        content={
                                            <>
                                                {
                                                    ['credit'].includes(c.key) &&
                                                    oc(props).discounts[c.key]([])
                                                        .filter(showCreditBenefit)
                                                        .map((benefit: CreditBenefit) => (
                                                            <CreditDiscount
                                                                key={benefit.tokenId}
                                                                benefit={benefit}
                                                                product={getProduct(benefit)}
                                                                onAddToCart={handleAddVoucherToCart(benefit, 'credit')}
                                                                onSelectUpdate={handleSelectionUpdate(benefit)}
                                                                reset={reset}
                                                            />
                                                        ))
                                                }
                                                {
                                                    ['voucher'].includes(c.key) &&
                                                    oc(props).discounts[c.key]([])
                                                        .filter(showVoucherBenefit)
                                                        .map((benefit: VoucherBenefit) => (
                                                            <VoucherDiscount
                                                                key={benefit.tokenId}
                                                                benefit={benefit}
                                                                onAddToCart={handleAddVoucherToCart(benefit, 'voucher')}
                                                                reset={reset}
                                                            />
                                                        ))
                                                }
                                                {
                                                    ['partner', 'myPartner'].includes(c.key) &&
                                                    oc(props).discounts[c.key]([])
                                                        .slice(0, 3)
                                                        .map(benefit => (
                                                            <PartnerBenefit
                                                                key={benefit.tokenId || benefit.benefitId}
                                                                benefit={benefit}
                                                            />
                                                        ))
                                                }
                                                {
                                                    ['partner', 'myPartner'].includes(c.key) &&
                                                    oc(props).discounts[c.key]([]).length > 3 &&
                                                    <DiscountLink
                                                        handleClick={() => props.history.push('/discounts/partner')}
                                                        discountCount={oc(props).discounts[c.key]([]).length}
                                                    />
                                                }
                                                {
                                                    ['vip'].includes(c.key) &&
                                                    oc(props).discounts[c.key]([])
                                                        .filter(isBenefitActive)
                                                        .map(benefit => (
                                                            <Discount
                                                                key={benefit.id}
                                                                benefit={benefit}
                                                            />
                                                        ))
                                                }
                                            </>
                                        }
                                    />
                                </ReferenceWrapper>
                            )
                        }
                    </Col>
                </Row>
            </Layout.Container>
        </Layout.Content>
    );
};

interface DispatchToProps {
    fetchProducts();

    fetchDiscounts: () => void;
    resetApliedFromCode: () => void;
    validateSelection: (items: { transaction: ProductSelection[]; product: string }) => void;
    setCartItems?(items: ProductSelection[]);

    openCart?();
}

const mapDispatchToProps: DispatchToProps = {
    fetchProducts: fetchProductsActions.request,
    fetchDiscounts: fetchDiscountsAction,
    resetApliedFromCode: resetCodeVerificationAction,
    validateSelection: validateProductSelectionActionsDiscount.request,
    setCartItems: setCartItemsAction,
    openCart: openCartAction,
};

interface StateToProps {
    products: Product[];
    discounts: DiscountsState;
    credit: number;
    isAppliedFromCode: boolean;
    verificationType: CodeVerifcationTypes | null;
    cartItems: ProductSelectionPopulated[];
    creditInCart: number;
    total: { [key: string]: TotalValue };
}

const mapStateToProps = (state: AppState): StateToProps => ({
    cartItems: selectCartPopulatedItems(state),
    discounts: selectDiscounts(state),
    credit: selectUserCredit(state),
    isAppliedFromCode: selectCodeVerificationApplied(state),
    verificationType: selectCodeVerificationType(state),
    products: selectProducts(state),
    total: selectDiscountPrices(state),
    creditInCart: selectCartCredit(state),
});

export const Discounts = withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(DiscountsComponent)
);

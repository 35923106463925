import React, { FunctionComponent, useEffect, useState } from 'react';
import { oc } from 'ts-optchain';
import { connect } from 'react-redux';

import {
    Button,
    DiscountBadge,
    Dropdown,
    Input,
    LocalizedString,
    Price,
    QtyPicker,
    LipnoIcon,
} from '../../../../../../../../common/components';
import { Product } from '../../../../../../../../common/models/Product';
import { i18n } from '../../../../../../../../common/services/i18n';
import { replaceAt } from '../../../../../../../../common/utils/redux-helpers';
import { VariantType } from '../../../../../../../../common/models/Benefit';
import { AppState, Season } from '../../../../../../../../common/models/AppState';
import { isProductValidAtDate, isProductVisible } from '../../../../../../../../common/utils/visibility-helpers';
import { EntryBase, TransactionDryRunBenefit } from '../../../../../../../../common/models/NewTransaction';
import { selectCartItemsCount } from '../../../../../../ducks/cart';
import { environment } from '../../../../../../../../environments/environment';

export interface VariantsSelection {
    id: string;
    count: number;
}

interface StateToProps {
    cartCount: number;
}

interface Props {
    product: Product;
    badges: TransactionDryRunBenefit[];
    entries: EntryBase[];
    maxVariants?: number;

    onPersonsSelect?(values: VariantsSelection[]): void;

    onChange?(values: VariantsSelection[]): void;

    onDropdownOpen?(): void;

    onOpenChange?(value: boolean): void;

    onClose?: () => void;

    initialState?: VariantsSelection[];
    onSubmit?: boolean;
    reset?: boolean;
    variants?: VariantType[];
    season?: Season;
    singleVariants: Record<string, EntryBase>;
    disabled?: boolean;
    open?: boolean;
    arrival?: string;
}

const VariantsDropdownComponent: FunctionComponent<Props & StateToProps> = ({
    product,
    onChange,
    badges,
    entries,
    variants,
    maxVariants,
    initialState,
    onSubmit,
    reset,
    season,
    singleVariants,
    onDropdownOpen,
    disabled,
    open,
    onOpenChange,
    arrival,
    cartCount,
    onClose,
}) => {
    const [selectedVariants, setVariants] = useState<VariantsSelection[]>([]);
    const [visibility, setVisibility] = useState<boolean>(false);
    const [seasonVariants, setSeasonVariants] = useState<Product[]>([]);

    useEffect(
        () => {
            if (product) {
                const vars = oc(product).variants([])
                    .filter(v => !arrival || isProductValidAtDate(v, arrival))
                    .filter(isProductVisible)
                    .filter(variant => !season || oc(variant).season([]).includes(season._id));

                if (variants) {
                    setSeasonVariants(
                        vars.filter(v => variants.some(vv => vv.variant === v._id))
                    );
                } else {
                    setSeasonVariants(vars);
                }

            }
        },
        [product, arrival]
    );
    useEffect(
        () => {
            const variants = initializePicker();
            setVariants(variants);
            onChange && onChange(variants);
        },
        [seasonVariants]
    );
    useEffect(
        () => {
            if (open && !visibility) {
                setVisibility(true);
                onOpenChange && onOpenChange(false);
            }
        },
        [open]
    );
    useEffect(
        () => {
            if (reset) {
                const variants = initializePicker();
                setVariants(variants);
                onChange && onChange(variants);
            }
        },
        [reset]
    );
    // price increment modal
    // useEffect(
    //     () => {
    //         if (initialised && onChange) {
    //             onChange(selectedVariants);
    //         }
    //     },
    //     [selectedVariants]
    // );

    useEffect(
        () => {
            if (initialState && onSubmit) {
                setVariants(initialState);
                onChange && onChange(initialState);
            }
        },
        [onSubmit]
    );

    function initializePicker() {
        if ((!initialState || !initialState.length || !initialState.some(e => e.count > 0)) && seasonVariants[0]) {
            return [{ id: seasonVariants[0]._id, count: 1 }];
        } else if ((!initialState || !initialState.length || !initialState.some(e => e.count > 0))) {
            return [];
        } else if (initialState) {
            return initialState;
        }

        return [];
    }

    function handleInputClick(value: boolean) {
        if (value) {
            onDropdownOpen && onDropdownOpen();
        } else if (!value && visibility) {
            onClose && onClose();
        }
        setVisibility(value);
    }

    function handleConfirmClick() {
        onChange && onChange(selectedVariants);
        onClose && onClose();
        setVisibility(false);
    }

    function selectStringVariantsSelection() {
        return selectedVariants.reduce((a, c) => {
            if (c.count > 0) {
                const selected = oc(product)
                    .variants([])
                    .find(v => v._id === c.id);
                if (selected) {
                    const selection = `${c.count}x ${seasonVariants &&
                    selected.name[i18n.language]}, `;
                    a = a + selection;
                }
            }
            return a;
        }, '');
    }

    function updateVariants(variant: Product, value) {
        // if we have information about max / min variant counts
        if (variants) {
            const variantLimit = variants.filter(item => item.variant === variant._id)[0];
            if (
                Number(variantLimit.countMin) !== -1 &&
                (value < variantLimit.countMin || value > variantLimit.countMax)
            ) {
                return;
            }
        }

        if (selectedVariants.find(v => v.id === variant._id)) {
            setVariants(
                replaceAt(selectedVariants, selectedVariants.findIndex(v => v.id === variant._id), {
                    prices: variant.prices,
                    id: variant._id,
                    count: value,
                })
            );
        } else {
            setVariants([...selectedVariants, { id: variant._id, count: value }]);
        }
    }

    function renderOverlayItem(variant: Product) {
        const count = oc(selectedVariants.find(v => v.id === variant._id)).count(0);
        const entry = entries.find(entry => entry.product.variantId === variant._id);
        const variantLimit = oc(variants)([]).find(item => item.variant === variant._id);
        const sum = selectedVariants.reduce((acc, s) => acc + s.count, 0);

        function handleSelectionChange(value: number) {
            updateVariants(variant, value);
        }

        const availableSpace = Math.max(0, environment.config.productCountLimit - sum - cartCount + count);

        function getMaxVariant(variantMax): number | undefined {
            const max = Math.min(variantMax !== -1 ? variantMax : Infinity, availableSpace);

            if (max === 0) {
                return 0;
            } else if (!maxVariants && max > 0) {
                return max;
            } else if (max > -1 && maxVariants) {
                return Math.min(max, maxVariants - sum + count);
            } else if (max === -1 && maxVariants) {
                return maxVariants - sum + count;
            }
            return undefined;
        }

        function getPrice() {
            return singleVariants.hasOwnProperty(variant._id)
                ? singleVariants[variant._id].amount.club
                : oc(variant).prices.club[i18n.language.toUpperCase()].price();
        }

        return (
            <div className="v-dropdown-overlay__item" key={variant._id}>
                <div className="v-dropdown-overlay__item__content">
                    <h5>
                        <LocalizedString value={variant.name}/>
                    </h5>
                    {variant.description && (
                        <p>
                            <LocalizedString value={variant.description}/> (
                            {entry ? (
                                entry.amount.club !== getPrice()
                                    ? (
                                        <strong>
                                            <Price value={entry.amount.club}/>
                                        </strong>
                                    ) : (
                                        <Price value={getPrice()} />
                                    )
                            ) : (
                                <Price
                                    value={getPrice()}
                                />
                            )}
                            )
                        </p>
                    )}
                </div>
                <QtyPicker
                    value={count}
                    onChange={handleSelectionChange}
                    upperBound={getMaxVariant(oc(variantLimit).countMax(-1))}
                    lowerBound={oc(variantLimit).countMin(-1) !== -1 ? oc(variantLimit).countMin() : undefined}
                />
            </div>
        );
    }

    function renderOverlay() {
        return (
            <div className="v-dropdown-overlay">
                {seasonVariants
                    .sort((a, b) => oc(a).placement.row(0) - oc(b).placement.row(0))
                    .map(variant => renderOverlayItem(variant))}

                {seasonVariants.length === 0 &&
                    <p className="v-dropdown-overlay__center">
                        {i18n.t('ticketSelect.variant.empty')}
                    </p>
                }

                {product &&
                    <p className="v-dropdown-overlay__description">
                        <LocalizedString html={product.note}/>
                    </p>
                }
                {badges.length > 0 && (
                    <div className="v-dropdown-overlay__badges">
                        {badges.map((badge, index) => (
                            <DiscountBadge key={index} benefit={badge}/>
                        ))}
                        <p>{i18n.t('ticketSelect.variant.gotBadges')}</p>
                    </div>
                )}
                <Button block onClick={handleConfirmClick}>
                    {i18n.t('ticketSelect.variant.personsSubmit')}
                </Button>
            </div>
        );
    }

    return (
        <Dropdown
            placeholder={i18n.t('ticketSelect.variant.label')}
            trigger={['click']}
            overlay={renderOverlay()}
            placement="topRight"
            visible={visibility}
            onVisibleChange={handleInputClick}
            disabled={disabled}
        >
            <Input
                label={i18n.t('ticketSelect.variant.label')}
                placeholder={i18n.t('ticketSelect.variant.placeholder')}
                addonAfter={<LipnoIcon type={LipnoIcon.types.ChevronDown}/>}
                value={selectStringVariantsSelection()}
                readOnly
            />
        </Dropdown>
    );
};

const mapStateToProps = (state: AppState): StateToProps => ({
    cartCount: selectCartItemsCount(state),
});

export const VariantsDropdown = connect(mapStateToProps)(VariantsDropdownComponent);

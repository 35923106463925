import { Icon } from 'antd';
import React, { FunctionComponent, ReactNode } from 'react';

import { Dropdown, Menu } from '../../components';

interface MenuItem {
    title: ReactNode;
    onClick?: () => void;
}

interface Props {
    menuItems?: MenuItem[];
    placeholder?: string;
    children?: ReactNode;
    visible?: boolean;
    onVisibleChange?: ((visible: boolean) => void);
}

const DropdownMenu: FunctionComponent<Props> = ({
    menuItems = [],
    placeholder,
    children,
    visible= false,
    onVisibleChange,
    ...bag
}) => {
    const handleVisibleChange = (visible: boolean) => {
        onVisibleChange && onVisibleChange(!visible);
    };
    const renderMenu = () => (
        <Menu>
            {menuItems.map((item, index) => (
                <Menu.Item onClick={item.onClick} key={index}>
                    <React.Fragment>{item.title}</React.Fragment>
                </Menu.Item>
            ))}
        </Menu>
    );

    return (
        <Dropdown visible={visible || false} onVisibleChange={handleVisibleChange}  overlay={renderMenu()} placeholder={placeholder} {...bag}>
            {children} <Icon type="down" />
        </Dropdown>
    );
};

export default DropdownMenu;

import React from 'react';
import moment, {Moment} from 'moment';
import {
    useDatePrices
} from '../../../../../../features/shopping/features/eshop/components/ProductSelect/components/DateSelectCarousel/useDatePrices';
import {environment} from '../../../../../../environments/environment';
import {
    VariantsSelection
} from '../../../../../../features/shopping/features/eshop/components/ProductSelect/components/VariantsDropdown';
import {Product} from '../../../../../models/Product';

type Props = {
    date: Moment
    selectedDate?: Moment | null;
    disabledDate?: ((current: (Moment | null)) => boolean);
    currentMonth: Moment;
};
const CalendarCell = ({date,  disabledDate, selectedDate, currentMonth}: Props) => {
    const isAfterToday = date.isSameOrAfter(moment(), 'day');
    const isDisabled = disabledDate ? disabledDate(date) : !isAfterToday;

    const isInCurrentMonth = date.month() === currentMonth.month() && date.year() === currentMonth.year();

    if (!isInCurrentMonth) {
        return null;
    }

    return (
        <div
            className={`simple-date-cell ${isDisabled ? 'disabled' : ''} ${
                selectedDate && selectedDate.isSame(date, 'day') ? 'selected' : ''
            }`}
            style={{
                textAlign: 'center',
                pointerEvents: isDisabled ? 'none' : 'auto',
            }}
        >
            <div className="date">{date.format('DD')}</div>
        </div>
    );
};

export default CalendarCell;

import React from 'react';
import {oc} from 'ts-optchain';
import {LocalizedString} from '../index';
import {i18n} from '../../services/i18n';
import {Product} from '../../models/Product';
import cn from 'classnames';
import {Season} from '../../models/AppState';
import {environment} from '../../../environments/environment';
import DynamicIcon from '../DynamicIcon';

interface Props {
    categories: Product[];
    onSelect: (category: Product) => void;
    season: Season;
}

const CategoriesList = (props: Props) => {
    return (
        <div className="category-list">
            <div className={cn('header', {'header--summer': props.season.type === 'summer', 'header--winter': props.season.type === 'winter'})}>
                <p>{i18n.t('categoriesList.title', {defaultValue: 'Vyberte si typ lístka'})} </p>
            </div>

            <div className="list">
                {oc(props)
                    .categories([])
                    .map((category, index) => {
                        return (
                            <div
                                key={index}
                                className="list-item"
                                onClick={() => props.onSelect(category)}
                            >
                                <DynamicIcon
                                    src={`${environment.mediaUrl}/category-eshop-icon/${category._id}`}
                                    alt="category-icon"
                                />
                                <LocalizedString value={category.name} />
                            </div>
                        );
                    })}
            </div>
        </div>
    );
};
export default CategoriesList;

import { Card, Button } from 'antd';
import React, { Fragment, useEffect, useMemo, useState } from 'react';
import Truncate from 'react-truncate';
import { oc } from 'ts-optchain';
import { connect } from 'react-redux';
import uuid from 'uuid/v4';


import { Price, Select, TotalPrice } from '../../components';
import { TotalValue, VoucherBenefit } from '../../models/Benefit';
import { i18n } from '../../services/i18n';
import { Product } from '../../models/Product';
import {
    VariantsDropdown,
    VariantsSelection,
} from '../../../features/shopping/features/eshop/components/ProductSelect/components/VariantsDropdown';
import {
    selectDiscountBadges,
    selectDiscountEntries,
    selectEshopSingleEntries, selectProducts, selectSeason,
    selectTransactionPrice,
    SingleVariant, validateProductSelectionActionsDiscount,
    validateSingleVariantActions
} from '../../../features/shopping/ducks';
import { AppState, Season } from '../../models/AppState';
import { environment } from '../../../environments/environment';
import { isProductAndAnyVariantVisible, isProductVisible } from '../../utils/visibility-helpers';
import DynamicImg from '../DynamicImg';
import { EntryBase, TransactionDryRunBenefit } from '../../models/NewTransaction';
import LocalizedString from '../LocalizedString';
import BenefitValidity from '../BenefitValidity/BenefitValidity';
import { SelectLabel } from '../../../features/shopping/features/eshop/components/ProductSelect/components/SelectLabel';
import { ProductSelection } from '../../models/Cart';
import moment from 'moment';

interface Props {
    benefit: VoucherBenefit;
    reset: boolean;
    onAddToCart: (value: VariantsSelection[], product: Product) => void;
    onSelectUpdate: (value: VariantsSelection[], product: Product) => void;
}

const VoucherDiscountComponent = (props: Props & StateToProps & DispatchToProps) => {
    const [selectedProduct, setSelectedProduct] = useState<Product | undefined>(undefined);
    const [variantsSelection, setVariantsSelection] = useState<VariantsSelection[]>([]);

    const products = useMemo(() =>
        props.benefit.products
            .map(productId => props.products.find(product => product._id === productId && !!product.variants))
            .filter(product => product && isProductAndAnyVariantVisible(product)) as Product[],
        [props.benefit.products]
    );

    useEffect(() => {
        if (!selectedProduct) {
            setSelectedProduct(products[0]);
        }
    }, [products]);

    const variants = useMemo(() => {
            if (!selectedProduct) {
                return [];
            }

            return props.benefit.variants
                .filter(variant =>
                    variant.countMax !== 0 &&
                    oc(selectedProduct).variants([]).some(v => v._id === variant.variant)
                );
        },
        [selectedProduct, props.benefit.variants]
    );

    const initialVariants = useMemo(() =>
        variants
            .filter(variant => variant.countMin !== -1)
            .map(variant => ({
                id: variant.variant,
                count: variant.countMin,
            })),
        [variants]
    );

    const handleSelectionUpdate = (variants: VariantsSelection[]) => {
        if (!selectedProduct) {
            return;
        }

        setVariantsSelection(variants);

        props.validateSelection({
            transaction: variants.reduce<ProductSelection[]>((items, variant) => ([
                ...items,
                ...Array.from({ length: variant.count }).map(() => ({
                    id: uuid(),
                    variant: variant.id,
                    product: selectedProduct._id,
                    arrival: moment().format(),
                    voucher: props.benefit.tokenId,
                }))
            ]), []),
            product: props.benefit.tokenId,
        });
    };

    const validateSingleVariants = () => {
        if (!selectedProduct) {
            return;
        }

        oc(selectedProduct)
            .variants([])
            .filter(v => isProductVisible(v))
            .forEach(v =>
                props.validateSingleVariant({ variant: v._id, arrival: new Date().toISOString() })
            );
    };

    const handleAddToCart = () => {
        if (!selectedProduct) {
            return;
        }

        props.onAddToCart(variantsSelection, selectedProduct);
    };

    function handleProductSelect(value) {
        if (value) {
            const product = props.products.find(item => item._id === value) as Product;

            setSelectedProduct(product);
        }
    }

    function getProductOptions() {
        return props.benefit.products
            .map(productId => props.products.find(product => product._id === productId && !!product.variants))
            .filter(product => product && isProductAndAnyVariantVisible(product))
            .map(product => ({
                label: <SelectLabel product={product!}/>,
                value: product!._id,
            }));
    }

    return (
        <div className="voucher-discount">
            <Card
                className="cart"
                cover={
                    <DynamicImg
                        srcs={[
                            `${environment.mediaUrl}/benefit/${props.benefit.benefitId}`,
                            `${environment.mediaUrl}/static/eshop/benefit.season-${props.season.type}`,
                            `${environment.mediaUrl}/static/eshop/benefit`,
                        ]}
                        alt="benefit"
                        style={{ margin: '0 auto' }}
                    />
                }
            >
                <Card.Meta
                    title={
                        <Truncate lines={2} ellipsis="...">
                            <LocalizedString value={props.benefit.name} />
                        </Truncate>
                    }
                    description={<BenefitValidity validity={props.benefit.validity} />}
                />
                <p>
                    <Truncate lines={5} ellipsis="...">
                        <LocalizedString value={props.benefit.description} />
                    </Truncate>
                </p>

                <div className={'ant-card-add-cart'}>
                    <TotalPrice
                        type="default"
                        pricePosition="bottom"
                        withDiscount={
                            <Price value={props.total.discounted || 0} />
                        }
                        withoutDiscount={
                            <Fragment>
                                {i18n.t('ticketSelect.priceWithoutDiscount')}{' '}
                                <Price value={props.total.price || 0} />
                            </Fragment>
                        }
                        displayDiscount
                    />
                    <Select
                        onChange={handleProductSelect}
                        placeholder={i18n.t('ticketSelect.product.placeholder')}
                        label={i18n.t('ticketSelect.product.label')}
                        value={oc(selectedProduct)._id()}
                        options={
                            products.map(product => ({
                                label: <SelectLabel product={product!}/>,
                                value: product!._id,
                            }))
                        }
                        style={{ width: '100%' }}
                        notFoundContent={i18n.t('ticketSelect.product.empty')}
                    />
                    <VariantsDropdown
                        badges={props.badges}
                        disabled={!selectedProduct}
                        product={selectedProduct}
                        onChange={handleSelectionUpdate}
                        entries={props.entries}
                        variants={variants}
                        maxVariants={props.benefit.maxUsage}
                        onDropdownOpen={validateSingleVariants}
                        initialState={initialVariants}
                        reset={props.reset}
                        singleVariants={props.singleVariants}
                    />
                    <Button
                        block
                        size="small"
                        disabled={!selectedProduct || !variantsSelection || !variantsSelection.some(v => v.count > 0)}
                        onClick={handleAddToCart}
                    >
                        {i18n.t('discount.cartButton')}
                    </Button>
                </div>
            </Card>
        </div>
    );
};

interface StateToProps {
    badges: TransactionDryRunBenefit[];
    entries: EntryBase[];
    singleVariants: Record<string, EntryBase>;
    total: TotalValue;
    season: Season;
    products: Product[];
}

const mapStateToProps = (state: AppState, props: Props): StateToProps => ({
    badges: selectDiscountBadges(state, props.benefit.tokenId),
    entries: selectDiscountEntries(state, props.benefit.tokenId),
    singleVariants: selectEshopSingleEntries(state),
    total: selectTransactionPrice(state, props.benefit.tokenId),
    season: selectSeason(state),
    products: selectProducts(state),
});

interface DispatchToProps {
    validateSingleVariant: (items: SingleVariant) => void;
    validateSelection: (items: { transaction: ProductSelection[]; product: string }) => void;
}

const mapDispatchToProps: DispatchToProps = {
    validateSingleVariant: validateSingleVariantActions.request,
    validateSelection: validateProductSelectionActionsDiscount.request,
};

const VoucherDiscount = connect(mapStateToProps, mapDispatchToProps)(VoucherDiscountComponent);

export default VoucherDiscount;
